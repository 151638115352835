/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, {useMemo, useState,useEffect} from "react";
import { Formik, useFormik } from "formik";
import {get, merge} from "lodash";
import Select from 'react-select';
import {FormHelperText, Switch} from "@material-ui/core";
import clsx from "clsx";
import axios from 'axios';
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import swal from 'sweetalert';
import {coy as highlightStyle} from "react-syntax-highlighter/dist/esm/styles/prism";
import {useHtmlClassService, setLayoutConfig, getInitLayoutConfig} from "../../layout";
import {Card, CardBody, CardHeader} from "../controls";
import {Table} from "react-bootstrap";
 import {Notice, KTCodeExample} from "../../../_metronic/_partials/controls";
 import * as Yup from "yup";
const localStorageActiveTabKey = "builderActiveTab";

const initialValues = {
    doctor_id: '',
    commison_amt: '',
   
};

export function Commision_master(props) {
    //debugger
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const htmlClassService = useHtmlClassService();

    const RegistrationSchema = Yup.object().shape({
        doctor_id: Yup.string()
            .required("Name is mandatory"),
            commison_amt: Yup.string()
            .required("Commision Amount is mandatory"),
            commision_type: Yup.string()
            .required("Commision Type is mandatory"),
    });
    
    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const { intl } = props;

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    
    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setSubmitting,resetForm  }) => {
            // debugger;
            /*Register API*/ 
            enableLoading();
            const API_PATH = 'http://103.250.185.131/~drapi/Authentication/addCommissionbyDoctor';
            axios.post(API_PATH, values)
                .then(
                    (response) => {
                        // debugger;
                        if (response.data.status == 200) {
                            swal({
                                title: "Done!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            });
                            resetForm(initialValues);
                        }
                        else if (response.data.status == 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                        setSubmitting(false);
                    }
                )
                .catch(
                    (error) =>{
                        console.log(error)
                    }
                );
        },
    });


    const dropDownChange = (event) =>{  //debugger;
         formik.values[event.target.doctor_id] = event.target.value;
        
        console.log(`Option selected:`, event.target.value);
    }

    const  handleChange = (event) => {
        this.setState({
            value: event.target.value
        });
    }

    const [page, setPage] = useState(1);
    useEffect(() => {
        const API_PATH1 = 'http://103.250.185.131/~drapi/Authentication/getDoctorList';
        axios.get(API_PATH1)
            .then(
                (response) => {  
                    if (response.data.status == 200) {
                         setState(prevState =>({...prevState,data:response.data.data}));
                       
                    }
                    else if (response.data.status == 400) {
                        swal({
                            title: "Error!",
                            text: response.data.message,
                            icon: "warning",
                            button: "Ok!",
                            dangerMode: true,
                        });
                    }
                    disableLoading();
                }
            )
            .catch(
                (error) =>{
                    console.log(error)
                }
            );
    
    }, [page]);


   
    const [state, setState] = React.useState({
        columns: [
          { title: 'Sr.No', field: 'id' },
          { title: 'Name', field: 'name' },
        //   { title: 'Email', field: 'email' },
        //   { title: 'Address', field: 'address' },
          { title: 'Phone', field: 'phone' },
          { title: 'Doctor Name', field: 'doctor_name' },
          { title: 'Total Appointment', field: 'Totalcount' },
        //   { title: 'Action', field: 'Totalcount' },
        ],
        data: [
        ]
      });

      const techCompanies = [
        { label: "Apple", value: 1 },
        { label: "Facebook", value: 2 },
        { label: "Netflix", value: 3 },
        { label: "Tesla", value: 4 },
        { label: "Amazon", value: 5 },
        { label: "Alphabet", value: 6 },
      ];

      
    var doctorList = state.data.length > 0 && state.data.map((item, i) => {
        return (
           <option key={i} value={item.id}>{item.name}</option>
          
        )
  
      });
    
    
    return (
      
    <div className="card card-custom">
                            {/*Header*/}
                            {  <div className="card-header card-header-tabs-line">
                                <ul
                                    className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                                    data-remember-tab="tab_id"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${key === 0 ? "active" : ""}`}
                                            data-toggle="tab"
                                            // onClick={() => {
                                            //     setKey(0);
                                            //     saveCurrentTab(0);
                                            // }}
                                        >
                                          Add  Commission Master
                                        </a>
                                    </li>
                                      
                                </ul>
                            </div> }

                            <div className="form">
                            <form action="" method="POST" autoComplete="off" onSubmit={formik.handleSubmit}  name="userRegistrationForm" id="affiliateSignup" >
                                <div className="card-body">
                                    <div className="tab-content pt-3">
                                        <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Name:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                 <select className="form-control" name="doctor_id" id="doctor_id" onChange={dropDownChange} {...formik.getFieldProps("doctor_id")}>
                                                         <option value="">-Select-</option>
                                                         {doctorList}
                                              
                                                   </select>  
                                                    {/* <Select
                                                    name="doctor_id" id="doctor_id"
                                                    
                                                    options={doctorList}
                                                    onChange={dropDownChange}
                                                    /> */}
                                                   {/* <input type="text" className="form-control" name="doctor_id"  maxLength="50" placeholder="Enter Amount"  {...formik.getFieldProps("doctor_id")}  /> */}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Commision Type:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                        <select className="form-control" name="commision_type" id="commision_type" onChange={dropDownChange} {...formik.getFieldProps("commision_type")}>
                                                             <option value="">-Select-</option>
                                                             <option value="percentage">Percentage</option>
                                                             <option value="amount">Amount</option>
                                                        </select>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Enter Amount:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                <input type="text" className="form-control" name="commison_amt"  maxLength="50" placeholder="Enter Amount"  {...formik.getFieldProps("commison_amt")}  />
                                                     {formik.touched.commison_amt && formik.errors.commison_amt ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.commison_amt}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                          
                                    </div>

                            <div className="card-footer">
                                    <div className="row">
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-9">
                                            <button
                                                type="submit"
                                                // onClick={handleSubmit}
                                                className={`btn btn-primary font-weight-bold mr-2`}
                                            >
                                                <i className="la la-eye"/> Submit
                                            </button>
                                            {" "}
                                            <button
                                                type="button"
                                                // onClick={handleReset}
                                                className={`btn btn-clean font-weight-bold mr-2`}
                                            >
                                                <i className="la la-recycle"/> Reset
                                            </button>
                                            {" "}
                                            <span
                                                className={`ml-3 ${clsx({
                                                    spinner: isLoading
                                                })}`}
                                            />
                                        </div>
                                    </div>
                                </div>

                                </div>
                               </form>                             
                                
                            </div>
                        </div>

       );
}
