/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, {useMemo, useState} from "react";
import { Formik, useFormik } from "formik";
import {get, merge} from "lodash";
import {FormHelperText, Switch} from "@material-ui/core";
import clsx from "clsx";
import axios from 'axios';
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import swal from 'sweetalert';
import {coy as highlightStyle} from "react-syntax-highlighter/dist/esm/styles/prism";
import {useHtmlClassService, setLayoutConfig, getInitLayoutConfig} from "../../layout";
import {Card, CardBody, CardHeader} from "../controls";
import {Table} from "react-bootstrap";
 import {Notice, KTCodeExample} from "../../../_metronic/_partials/controls";
 import * as Yup from "yup";
const localStorageActiveTabKey = "builderActiveTab";

const initialValues = {
    username: '',
    email: '',
    address: '',
    phone: '',
    specialization: '',
    qualification: '',
    focus_area: ''
};

export function Builder(props) {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const htmlClassService = useHtmlClassService();

    const RegistrationSchema = Yup.object().shape({
        username: Yup.string()
            .required("Name is mandatory"),
        email: Yup.string()
            .required("Email is mandatory"),
        address: Yup.string()
            .required("Address is mandatory"),
        phone: Yup.string()
            .required("Mobile is mandatory"),
        specialization: Yup.string()
            .required("Specialization is mandatory"),
        qualification: Yup.string()
            .required("Qualification is mandatory"),
        focus_area: Yup.string()
            .required("Focus Area is mandatory")  
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const { intl } = props;
 
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setSubmitting,resetForm  }) => {
            //  debugger;
            /*Register API*/ 
            enableLoading();
            const API_PATH = 'http://103.250.185.131/~drapi/Authentication/registerDoctor';
            axios.post(API_PATH, values)
                .then(
                    (response) => {
                        if (response.data.status == 200) {
                            swal({
                                title: "Done!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            });
                            resetForm(initialValues);
                        }
                        else if (response.data.status == 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                        setSubmitting(false);
                    }
                )
                .catch(
                    (error) =>{
                        console.log(error)
                    }
                );
        },
    });

	
    return (
               
                        <div className="card card-custom">
                            {/*Header*/}
                            {  <div className="card-header card-header-tabs-line">
                                <ul
                                    className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                                    data-remember-tab="tab_id"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${key === 0 ? "active" : ""}`}
                                            data-toggle="tab"
                                            // onClick={() => {
                                            //     setKey(0);
                                            //     saveCurrentTab(0);
                                            // }}
                                        >
                                            Add New Doctor
                                        </a>
                                    </li>
                                      
                                </ul>
                            </div> }

                            <div className="form">
                            <form action="" method="POST" autoComplete="off" onSubmit={formik.handleSubmit}  name="userRegistrationForm" id="affiliateSignup" >
                                <div className="card-body">
                                    <div className="tab-content pt-3">
                                        <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Name:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                     <input type="text" className="form-control" name="username"  maxLength="50" placeholder="Enter Full Name"  {...formik.getFieldProps("username")} />
                                                     {formik.touched.username && formik.errors.username ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.username}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Email:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                     <input type="text" className="form-control" name="email"  maxLength="50" placeholder="Enter Email"  {...formik.getFieldProps("email")}  />
                                                     {formik.touched.email && formik.errors.email ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.email}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Address:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <input type="text" className="form-control" name="address"  maxLength="50" placeholder="Enter Address"  {...formik.getFieldProps("address")} />
                                                    {formik.touched.address && formik.errors.address ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.address}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Mobile No:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                       <input type="text" className="form-control" name="phone"  maxLength="10" placeholder="Enter Mobile No." {...formik.getFieldProps("phone")}  /> 
                                                       {formik.touched.phone && formik.errors.phone ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.phone}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Specialization:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                         <input type="text" className="form-control" name="specialization" id="specialization" maxLength="50" placeholder="Enter Specialization."  {...formik.getFieldProps("specialization")}  /> 

                                                     {formik.touched.specialization && formik.errors.specialization ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.specialization}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Qualification:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                <input type="text" className="form-control" name="qualification" id="qualification" maxLength="50" placeholder="Enter Qualification."  {...formik.getFieldProps("qualification")} />
                                                {formik.touched.qualification && formik.errors.qualification ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.qualification}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Focus Area:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <input type="text" className="form-control" name="focus_area"  id="focus_area"  maxLength="255" placeholder="Enter Focus Area"  {...formik.getFieldProps("focus_area")} />
                                                    {formik.touched.focus_area && formik.errors.focus_area ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.focus_area}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                         </div>
                                          
                                    </div>

                            <div className="card-footer">
                                    <div className="row">
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-9">
                                            <button
                                                type="submit"
                                                // onClick={handleSubmit}
                                                className={`btn btn-primary font-weight-bold mr-2`}
                                            >
                                                <i className="la la-eye"/> Submit
                                            </button>
                                            {" "}
                                            <button
                                                type="button"
                                                // onClick={handleReset}
                                                className={`btn btn-clean font-weight-bold mr-2`}
                                            >
                                                <i className="la la-recycle"/> Reset
                                            </button>
                                            {" "}
                                            <span
                                                className={`ml-3 ${clsx({
                                                    spinner: isLoading
                                                })}`}
                                            />
                                        </div>
                                    </div>
                                </div>

                                </div>
                               </form>                             
                                
                            </div>
                        </div>

    );
}
