/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, {useMemo, useState,useEffect} from "react";
import { Formik, useFormik } from "formik";
import {get, merge} from "lodash";
import {FormHelperText, Switch} from "@material-ui/core";
// import DatePicker from 'react-datepicker';
// import DatePicker from "react-datepicker";
// import SelectSearch from 'react-select-search';
import clsx from "clsx";
import axios from 'axios';
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import swal from 'sweetalert';
import {coy as highlightStyle} from "react-syntax-highlighter/dist/esm/styles/prism";
import {useHtmlClassService, setLayoutConfig, getInitLayoutConfig} from "../../layout";
import {Card, CardBody, CardHeader} from "../controls";
import {Table} from "react-bootstrap";
 import {Notice, KTCodeExample} from "../../../_metronic/_partials/controls";
 import * as Yup from "yup";
const localStorageActiveTabKey = "appointmentActiveTab";

export function Appointment() {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const htmlClassService = useHtmlClassService();
    const doctor_list = [];

//    const [startDate, setStartDate] = useState(new Date());

    const initialValues = {
        patient_id: '',
        doctor_id: '',
        date: '',
        time_slot: '',
        consultType: '',
        appointmentType: '',
        appointmentType: '',
        service_id: '',
        data: []
     
    };

    
    const RegistrationSchema = Yup.object().shape({
        patient_id: Yup.string()
            .required("Select Patient Name "),
            date: Yup.string()
            .required("Date is mandatory"),
            time_slot: Yup.string()
            .required("Time Slot is mandatory"),
            consultType: Yup.string()
            .required("Consult Type is mandatory"),
            appointmentType: Yup.string()
            .required("Appointment Type is mandatory"),
            service_id: Yup.string()
            .required("Service is mandatory")
       
    });
    const APP_URL = 'http://localhost:3000/admin/Authentication/';     

    const saveCurrentTab = (_tab) => {
        localStorage.setItem(localStorageActiveTabKey, _tab);
    }

    const enableLoading = () => {
        setLoading(true);
    };
    const disableLoading = () => {
        setLoading(false);
    };
   
      
    const [state, setState] = React.useState({
        columns: [
      
        ],
        data: [
        ]
      });
 
      const [page, setPage] = useState(1);   
      useEffect(() => {
    const list_api_url = `http://103.250.185.131/~drapi/Authentication/getAllPatientList`;
    /*Get doctor list*/
    axios.get(list_api_url)
        .then(response => {   
            if (response.data.status == 200) {
                //  let  league_name;
                // this.setState({doctor_list: res.data.data})  
                // this.doctor_list= res.data.data;      
                console.log("list  "+response.data.data);      
                setState(prevState =>({...prevState,data:response.data.data}));
            }
            else if(response.data.status == 400)
            {
            this.setState({doctor_list: response.data.data})              
                console.log('something error occured');
            }
    })
}, [page]);

    /*get patinet list*/

    
    const dropDownChange = (event) =>{  //debugger;
        formik.values[event.target.doctor_id] = event.target.value;
       
       console.log(`Option selected:`, event.target.value);
   }

   

    const formik = useFormik({

        
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // debugger;
            /*Register API*/ 
            enableLoading();
            const API_PATH = 'http://103.250.185.131/~drapi/Authentication/add_appointment';
            axios.post(API_PATH, values)
                .then(
                    (response) => {
                        if (response.data.status == 200) {
                            swal({
                                title: "Done!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            });
                         
                        }
                        else if (response.data.status == 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                    }
                )
                .catch(
                    (error) =>{
                        console.log(error)
                    }
                );
        },
    });

       //patient list
      
    var patientList = state.data.length > 0 && state.data.map((item, i) => {
        return (
           <option key={i} value={item.id}>{item.name}</option>
          
        )
  
      });
    
  
    //  doctor_list  = this.state.doctor_list;
    return (
        <>
          
            {/* <Formik
                initialValues={initialValues}
                onSubmit={values => {
                    setIsLoading(true);
                    setLayoutConfig(values);
                }}
                onReset={() => {
                    setIsLoading(true);
                    setLayoutConfig(getInitLayoutConfig());
                }}
            > */}
                {/* {({values, handleReset, handleSubmit, handleChange, handleBlur}) => ( */}
                    {/* <> */}
                        <div className="card card-custom">
                            {/*Header*/}
                            <div className="card-header card-header-tabs-line">
                                <ul
                                    className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                                    data-remember-tab="tab_id"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${key === 0 ? "active" : ""}`}
                                            data-toggle="tab"
                                            onClick={() => {
                                                setKey(0);
                                                saveCurrentTab(0);
                                            }}
                                        >
                                            Add New Appointment (Clinic Only)
                                        </a>
                                    </li>
                                      
                                </ul>
                            </div>

                            <div className="form">
                            <form action="" method="POST" autoComplete="off" onSubmit={formik.handleSubmit}  name="userRegistrationForm" id="affiliateSignup" >
                                <div className="card-body">
                                    <div className="tab-content pt-3">
                                        <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Name:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                     {/* <input type="text" className="form-control" name="patient_id"  maxLength="50" placeholder="Enter Name" /> */}
                                                     
                                                     <select className="form-control" name="patient_id" id="patient_id" onChange={dropDownChange} {...formik.getFieldProps("patient_id")}>
                                                         <option value="">-Select-</option>
                                                         {patientList}
                                              
                                                    </select>
                                                     {formik.touched.patient_id && formik.errors.patient_id ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.patient_id}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Date:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                     <input type="date" className="form-control" name="date"  maxLength="50" placeholder="Date"   />
                                                     {/* <DatePicker selected={startDate} onChange={date => setStartDate(date)} /> */}
                                                     {formik.touched.date && formik.errors.date ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.date}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                         
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Time Slot:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <input type="text" className="form-control" name="time_slot"  maxLength="50" placeholder="Time Slot" />

                                                    {formik.touched.time_slot && formik.errors.time_slot ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.time_slot}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Services
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                <select  className="form-control" name="service_id"   id="service_id"  onChange={dropDownChange}  {...formik.getFieldProps("service_id")} >
                                                    <option value="">Select</option>
                                                    <option value="chat">Chat</option>
                                                    <option value="audio">Audio</option>
                                                    <option value="video">Video</option>
                                                    <option value="clinic">Clinic</option>
                                                 
                                                 </select>
                                                    {formik.touched.service_id && formik.errors.service_id ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.service_id}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Consult Type:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                       <input type="text" className="form-control" name="consultType"  maxLength="10" placeholder="Consult Type." />
                                                       {formik.touched.consultType && formik.errors.consultType ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.consultType}</div>
                                                        </div>
                                                    ) : null} 
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Enter Amount
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                         <input type="number" className="form-control" name="amount"  min="1" placeholder="Amount." /> 
                                                         {formik.touched.amount && formik.errors.amount ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.amount}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Appointment Type:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                         {/* <input type="text" className="form-control" name="appointment_type"  min="1" placeholder="Amount." />  */}
                                                         <select  className="form-control" name="appointmentType"   id="appointmentType"  onChange={dropDownChange}  {...formik.getFieldProps("appointmentType")} >
                                                            <option value="">Select</option>
                                                            <option value="First Visit">First Visit</option>
                                                            <option value="Routine">Routine</option>
                                                            <option value="Follow-up">Follow-up</option>
                                                            <option value="Procedure/Vaccination">Procedure/Vaccination</option>
                                                            <option value="Dressing/Plaster">Dressing/Plaster</option>
                                                            <option value="Other">Other</option>
                                                        
                                                        </select>
                                                         {formik.touched.appointmentType && formik.errors.appointmentType ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.appointmentType}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            

                                        </div>
                                      
                                        
                                    </div>
                                </div>

                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-9">
                                            <button
                                                type="submit"
                                                // onClick={handleSubmit}
                                                className={`btn btn-primary font-weight-bold mr-2`}
                                            >
                                                <i className="la la-eye"/> Submit
                                            </button>
                                            {" "}
                                            <button
                                                type="button"
                                                // onClick={handleReset}
                                                className={`btn btn-clean font-weight-bold mr-2`}
                                            >
                                                <i className="la la-recycle"/> Reset
                                            </button>
                                            {" "}
                                            <span
                                                className={`ml-3 ${clsx({
                                                    spinner: isLoading
                                                })}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                               </form>                             
                               
                            </div>
                        </div>

                        {/*Config*/}
                        
                    {/* </> */}
                {/* // )} */}
            {/* </Formik> */}
        </>
    );
}
