/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, {useMemo, useState} from "react";
import { Formik, useFormik } from "formik";
import MaterialTable from 'material-table';
import {get, merge} from "lodash";
import {FormHelperText, Switch} from "@material-ui/core";
import clsx from "clsx";
import axios from 'axios';
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import swal from 'sweetalert';
import {coy as highlightStyle} from "react-syntax-highlighter/dist/esm/styles/prism";
import {useHtmlClassService, setLayoutConfig, getInitLayoutConfig} from "../../layout";
import {Card, CardBody, CardHeader} from "../controls";
import {Table} from "react-bootstrap";
import {Notice, KTCodeExample} from "../../../_metronic/_partials/controls";
import * as Yup from "yup";
const localStorageActiveTabKey = "patientActiveTab";

export function PatientList() {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const htmlClassService = useHtmlClassService();
    const initialValues = {
        patientName: '',
        email: '',
        address: '',
        phone: '',
        gender: '',
        bloodgroup: '',
        birthdate: '',
        patientList:{}
    };

        
    const enableLoading = () => {
        setLoading(true);
    };
    const disableLoading = () => {
        setLoading(false);
    };

     
    const [state, setState] = React.useState({
        columns: [
          { title: 'Sr.No', field: 'id' },
          { title: 'Name', field: 'name' },
        //   { title: 'Email', field: 'email' },
        //   { title: 'Address', field: 'address' },
          { title: 'Phone', field: 'phone' },
          { title: 'Doctor Name', field: 'doctor_name' },
          { title: 'Total Appointment', field: 'Totalcount' },
        //   { title: 'Action', field: 'Totalcount' },
        ],
        data: [
        ]
      });
    /**/

    const API_PATH = 'http://103.250.185.131/~drapi/Authentication/getPatientList';
            axios.get(API_PATH)
                .then(
                    (response) => {  
                        if (response.data.status == 200) {
                            setState(prevState =>({...prevState,data:response.data.data}));
                            //setState(...state.columns,{data:[{name:"Rahul J",email:"rahjdsf",address:"asdasdfasdf",qualification:"asdfasdf",specialization:"sdfsdfdsf",focus_area:"asdasdsa"}]});
                            // swal({
                            //     title: "Done!",
                            //     text: response.data.message,
                            //     icon: "success",
                            //     button: "Ok!",
                            // });
                           
                        }
                        else if (response.data.status == 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                    }
                )
                .catch(
                    (error) =>{
                        console.log(error)
                    }
                );

    return (
               
        <MaterialTable
        title="Patient List"
        columns={state.columns}
        data={state.data}
        
        

      />

    );
}
