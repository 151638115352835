import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {DoctorListPage} from "./pages/DoctorListPage";
import {PatientPage} from "./pages/PatientPage";
import {PatientListPage} from "./pages/PatientListPage";
import {AppointmentPage} from "./pages/AppointmentPage";
import {AppointmentListPage} from "./pages/AppointmentListPage";
import {PaymentListPage} from "./pages/PaymentListPage";
import {Commision_master_Page} from "./pages/Commision_master_Page";
import {Update_Commision_master_Page} from "./pages/Update_Commision_master_Page";
import {Commision_master_list_Page} from "./pages/Commision_master_list_Page";
// import {feedback_page} from "./pages/feedback_page";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/doctor" component={DoctorListPage}/>
                <ContentRoute path="/patient" component={PatientPage}/>
                <ContentRoute path="/patientList" component={PatientListPage}/>
                <ContentRoute path="/appointment" component={AppointmentPage}/>
                <ContentRoute path="/appointmentList" component={AppointmentListPage}/>
                <ContentRoute path="/payment" component={PaymentListPage}/>
                <ContentRoute path="/commision_master" component={Commision_master_Page}/>
                <ContentRoute path="/update_commision_master" component={Update_Commision_master_Page}/>
                <ContentRoute path="/commision_master_list" component={Commision_master_list_Page}/>
                {/* <ContentRoute path="/feedback_review" component={feedback_page}/> */}
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
